import React from "react";

const Form = () => {
  return (
    <div className="home_contact">
      <form action="contact.php" method="POST">
        <input
          className="form-control form-control-lg"
          name="name"
          id="name"
          type="text"
          placeholder="Vaše meno*"
          required
          aria-label=".form-control-lg example"
        />
        <input
          className="form-control form-control-lg"
          name="phone"
          id="phone"
          type="number"
          placeholder="Vaše tel. číslo"
          aria-label=".form-control-lg example"
        />
        <input
          className="form-control form-control-lg"
          name="email"
          id="email"
          type="email"
          placeholder="Váš email*"
          required
          aria-label=".form-control-lg example"
        />
        <textarea
          className="form-control pt-4"
          name="message"
          id="message"
          placeholder="Vaša správa"
          rows="3"
        ></textarea>
        <div className="btn_group">
          <button type="submit" className="btn olive">
            Poslať mail
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
