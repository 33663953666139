import React from "react";
import ServiceCard from "../components/ServiceCard";

const Services = () => {
  return (
    <section className="services bg-dark-100 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ServiceCard
              number="01"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Stavebná inštalácia"
              text="Royard prináša špičkové stavebné inštalačné služby, zabezpečujúce spoľahlivosť a kvalitu."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="02"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Automatizácia"
              text="Inovujte svoje podnikanie s Royardovými automatizačnými riešeniami."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="03"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Elektroinštalácia"
              text="Royard poskytuje komplexné elektroinštalačné služby od návrhu až po realizáciu."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
