import React from "react";
import ServiceCard from "../components/ServiceCard";
import FormContainer from "../containers/FormContainer";

const ServiceContainer01 = () => {
  return (
    <main className="wrapper">
      <section className="services inner in-service pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <ServiceCard
                number="2024"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Konštrukcia a montáž rozvodov vo výrobe"
                text="Severné Porýnie-Vestfálsko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="2023"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Výroba obrábacích liniek"
                text="Bádensko-Württembersko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="2023"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Výroba riadiacej techniky"
                text="Bádensko-Württembersko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="2023"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Robotika - uvedenie do prevádzky"
                text="Bavorsko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="2023"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Plynová elektráreň"
                text="Frankfurt nad Mohanom, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="2023"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Kancelárie pre refinériu"
                text="Bavorsko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="2022"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Technická údržba dopravých zariadení"
                text="Severné Porýnie - Vestfálsko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="06"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Výroba riadiacej techniky"
                text="Severné Porýnie-Vestfálsko, DE"
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="06"
                imgDark="https://placehold.co/600x400"
                imgLight="https://placehold.co/600x400"
                title="Lakovňa, Klimatizácia a sklad"
                text="Morava, CZ"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <Testimoals /> */}
      <FormContainer />
    </main>
  );
};

export default ServiceContainer01;
