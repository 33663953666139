import React from "react";
import Form from "../../components/Form";

const AsideInfo = () => {
  return (
    <div className="aside_info_wrapper">
      <button className="aside_close">
        <i className="bi bi-x-lg"></i>
      </button>
      <div className="aside_logo">
        <a href="/" className="light_logo">
          {/* <img src="images/logo-light-lg.svg" alt="logo" /> */}
          <h1>Logo</h1>
        </a>
        <a href="/" className="dark_logo">
          {/* <img src="images/logo-dark-lg.svg" alt="logo" /> */}
          <h1>Logo</h1>
        </a>
      </div>
      <div className="aside_info_inner">
        <p>V prípade záujmu o spoluprácu nás neváhajte kontaktovať.</p>

        <div className="aside_info_inner_box">
          <h5>Kontaktné informácie</h5>
          <p>+421 918 817 354</p>
          <p>+421 911 358 519</p>

          <p>office@royard.sk</p>

          <h5>Sídlo firmy</h5>
          <p>Royard RS s.r.o.</p>
          <p>Pázmaňa 49/3</p>
          <p>Šaľa 927 01</p>
          <p>Slovensko</p>
        </div>
        <div className="social_sites">
          <ul className="d-flex align-items-center justify-content-center">
            <li>
              <a>
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="bi bi-twitter"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="bi bi-instagram"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="bi bi-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
        <Form></Form>
      </div>
    </div>
  );
};

export default AsideInfo;
