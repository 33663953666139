import React from "react";
import Video from "../components/Video";
import FormContainer from "../containers/FormContainer";
import FunFacts from "../containers/FunFacts/FunFacts";
import MissionContainer from "../containers/MissionContainer";
import ServiceContainer01 from "../containers/ServiceContainer";
import TeamContainer02 from "../containers/TeamContainers/TeamContainer02";
import Testimonials from "../containers/Testimonials";
import TextAbout from "./TextAbout";

const AboutUs = () => {
  return (
    <main className="wrapper">
      <MissionContainer />

      <div className="video-block">
        <TextAbout />
      </div>
      <div className="">
        <FunFacts className="funfacts pd-top-lg bg_3" />
      </div>
      {/* <ServiceContainer01 /> */}

      {/* <TeamContainer02 /> */}
      {/* <Testimonials /> */}
      <FormContainer />
    </main>
  );
};

export default AboutUs;
