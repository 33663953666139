import React from "react";

const TextAbout = () => {
  return (
    <section className="highlight_banner bg-dark-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11 p-lg-0">
            <p className=" text-white about_para text-center">
              Identita spoločnosti spočíva v návrhoch riešení rôznych projektov
              pre našich klientov súvisiacich s technologickým pokrokom 21
              storočia.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextAbout;
