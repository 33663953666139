import React from "react";

const MissionContainer = () => {
  return (
    <section className="mission">
      <div className="container">
        <div className="mission_top_part">
          <div className="section-header text-center">
            <h4 className="text-white text-uppercase">O Nás</h4>

            <p className="text-white">
              Spoločnosť Royard RS bola založená spoločenstvom Technikov a
              Odborníkov pôsobiacich na trhu EU
            </p>

            <p className="text-white">
              Počas rokov pôsobenia v Nemeckom Elektrotechnickom priemysle sme
              nepretižite získavali skúseností od rozvíjajúcich sa spoločností.
              Po viac než 5tich rokoch môžeme konštatovať, že vynaložené úsilie,
              neustále cestovanie, oslovovanie nových klientov a spolupráce s
              odbornou verejnosťou stáli za zrodom. „Royardu“Logo“- Namiesto
              fotky.
            </p>
          </div>
          <div className="has_line">
            <h1 className="text-white text-uppercase text-center">
              Royard RS Logo
            </h1>
          </div>
          {/* <img src="images/about/team.png" alt="" /> */}
        </div>

        <div className="mission_bottom_part">
          <div className="row justify-content-center">
            <div className="col-lg-4 pe-lg-4">
              <div className="section-header">
                <h4 className="text-white text-uppercase border-line">
                  ...Spoločnosťou ktorej prioritou,je priniesť na trh Vyššiu
                  kvalitu, efektivitu a pracovné nasadenie
                </h4>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="mission_content">
                <p>
                  Pre našich klientov zabezpečujeme Uvedenie do prevádzky
                  rôznych technických zariadení na základe tech. Dokumentácie.
                  ,Rovnako tak technické vybavenie budov od začiatku cez
                  manažment až po odovzdanie Inštalácie vo finálnej podobe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionContainer;
