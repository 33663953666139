import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import HomeLayout02 from "../Layouts/HomeLayout02";
import HomeLayout03 from "../Layouts/HomeLayout03";
import HomeLayout04 from "../Layouts/HomeLayout04";
import ShopLayout from "../Layouts/ShopLayout";
import AboutUs from "../Pages/AboutUs";
import Blog from "../Pages/BlogPages/Blog";
import BlogDetails from "../Pages/BlogPages/BlogDetails";
import CommingSoon from "../Pages/CommingSoon";
import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";
import Home02 from "../Pages/HomePages/Home02";
import Home03 from "../Pages/HomePages/Home03";
import Home04 from "../Pages/HomePages/Home04";
import Home05 from "../Pages/HomePages/Home05";
import Home06 from "../Pages/HomePages/Home06";
import Home07 from "../Pages/HomePages/Home07";
import Home08 from "../Pages/HomePages/Home08";
import Home09 from "../Pages/HomePages/Home09";
import Home10 from "../Pages/HomePages/Home10";
import Login from "../Pages/Login";
import Project01 from "../Pages/ProjectPages/Project01";
import Project02 from "../Pages/ProjectPages/Project02";
import Project03 from "../Pages/ProjectPages/Project03";
import ProjectDetails from "../Pages/ProjectPages/ProjectDetails";
import ProjectDetails02 from "../Pages/ProjectPages/ProjectDetails02";
import Service01 from "../Pages/ServicePages/Service01";
import Service02 from "../Pages/ServicePages/Service02";
import ServiceDetails from "../Pages/ServicePages/ServiceDetails";
import Shop01 from "../Pages/ShopPages/Shop01";
import Shop02 from "../Pages/ShopPages/Shop02";
import Shop03 from "../Pages/ShopPages/Shop03";
import ShopCart from "../Pages/ShopPages/ShopCart";
import ShopCheckout from "../Pages/ShopPages/ShopCheckout";
import ShopProduct from "../Pages/ShopPages/ShopProduct";
import Team from "../Pages/TeamPages/Team";
import TeamDetails from "../Pages/TeamPages/TeamDetails";
import ThankYou from "../Pages/ThankYou";
import { productsAndCartData } from "../components/forJSON/getCart&ProductsData";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <Home01 />,
      },
      {
        path: "/gallery",
        element: <Project02 />,
      },
      {
        path: "/projects",
        element: <Service01 />,
      },

      {
        path: "/about",
        element: <AboutUs />,
      },

      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
