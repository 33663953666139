import React from "react";
import Paragraph from "./Paragraph";
import SubHeading from "./SubHeading";

const FormText = () => {
  return (
    <div className="col-lg-5">
      <div className="section-header">
        <SubHeading title="V prípade otázok v elektropriemysle sme Vám k dispozícii."></SubHeading>
        <Paragraph text="Pomoc pri realizácii až po samotné riadenie."></Paragraph>
        <Paragraph text="Kontaktujte nás už dnes a ušetríte čas i elektrnické straty."></Paragraph>
      </div>
    </div>
  );
};

export default FormText;
